.dgf-list--event {
  .card--event {
    .card-date {
      line-height: 1;
      margin-bottom: 24px;

      @include mq(sm) {
        margin-top: 0;
        margin-bottom: 0;
        height: 96px;
      }

      &__from {
        margin-bottom: 0;
        font-weight: 600;
      }
      &__to {
        font-weight: 600;
      }
    }

    &.card--has-status {
     .card-date {
       margin-top: 48px;

       @include mq(sm) {
         margin-top: 40px;
       }

     }
    }

    .card-title {
      &:not(:has(> a)) {
        @include truncate-text($rows: 2);
      }

      > a {
        @include truncate-text($rows: 2);
      }
    }

    .card-text {
      &__info {
        flex: 1;
      }
    }

    .button {
      &--event-state {
        position: absolute;
        left: 16px;
        top: 16px;

        padding: 0.35rem 1rem;

        background-color: $color__gray;
        color: $color__white;

        transition: none;
        border:none;
      }
      &--canceled {
        background-color: $color__danger;
      }
    }
  }

  &:not(.dgf-list--list) {
    .card--event {
      &.card--has-image {
        .card-date {
          @include mq(sm) {
            position: absolute;
            left: 0;
            transform: translateY(calc(-100% - #{var(--bs-card-spacer-y)}));

            min-width: 215px;
            max-width: 90%;

            margin-top: 0;
            margin-bottom: 0;
            padding: calc(var(--bs-card-spacer-y) * .25) var(--bs-card-spacer-y);

            display: flex;
            flex-direction: column;
            justify-content: center;

            border-top: 1px solid $color__gray;
            border-right: 1px solid $color__gray;
            border-bottom: 1px solid $color__gray;
            border-top-right-radius: var(--bs-border-radius);
            background-color: rgba($color__white, .95);
          }
        }

      }
    }
  }

  &.dgf-list--list {
    .card--event {

      .card-date {
        flex: 1;
        height: auto;
      }
      .card-title {
        flex: 2;
      }
      .card-text {
        flex: 1;
        flex-direction: column;

        &__info {
          flex: 0;
        }

        &__icon {
          //margin-top: 0;
          display: none;
        }
      }

      .button {
        &--event-state {
          left: 0;
        }
      }

      &.card--has-status {
        .card-date {
          @include mq(md,max) {
            margin-top: 48px;
            margin-bottom: 24px;
            height: auto;
          }
        }
      }

    }
  }

}

.event-list{
  &__footer{
      .splide__arrow{
          &--prev{
              img{
                  transform: rotate(180deg);
              }
          }
      }
  }
}